<template>
  <div class="header-wrapper relative w-full flex items-center justify-center">
    <div class="header-inner flex flex-col items-center justify-end">
      <div class="header container flex flex-col relative">
        <h3>쉽고 간편한, 인테리어 비교견적</h3>
        <p>인테리어 및 리모델링을 원하시는 공간,</p>
        <h1>
          쉽고 빠르게 전문가가 비교 견적을 보내드립니다.
        </h1>

        <img class="header-image-1" src="@/assets/jumbotron-2.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<style scoped lang="scss">
.header-wrapper {
  height: 600px;
  background-color: #283bf1;

  .header-inner {
    z-index: 2;
    padding-top: 40px;
    margin-bottom: -67px;
  }
}

.header-image-1 {
  position: absolute;
  width: 150px;
  bottom: -140px;
  right: -220px;
  z-index: 1;
}

.header {
  h3 {
    font-size: 40px;
    font-weight: 500;
    color: white;
    margin-bottom: 40px;
  }

  p {
    font-size: 24px;
    font-weight: 300 !important;
    color: white;
  }

  h1 {
    font-size: 24px;
    margin: 0;
    color: white;
    font-weight: 500;
  }
}

.jumbotron-title-2 {
  margin-left: 10px !important;
}

@media (max-width: 1023px) {
  .header-image-1 {
    width: 120px;
    right: -110px;
  }
}

@media (max-width: 767px) {
  .header-image-1 {
    display: none;
  }
  .header {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
    h1 {
      font-size: 18px;
    }
  }
  .header-wrapper {
    height: 400px;
    .header-inner {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 639px) {
  .header {
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
    h1 {
      font-size: 14px;
    }
  }
  .header-wrapper {
    height: 300px;
  }
}
</style>
