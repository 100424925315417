<template>
  <div class="w-full">
    <Jumbotron2 />
    <div
      v-if="step === 0"
      class="interior-offer-create-0 flex flex-col items-center"
    >
      <h1>
        당신만의 공간 인테리어,<br />
        쉽고 빠르게 <span>견적 받아보세요.</span>
      </h1>
      <p>
        인테리어 및 리모델링을 원하시는 공간을 알려주시면,<br />
        시공전문가가 쉽고 빠르게 비교 견적을 보내드립니다.
      </p>
      <button @click="onStart">인테리어 비교 견적받기</button>
    </div>

    <div
      v-if="step !== 0"
      class="flex-1 w-full flex justify-center items-center"
    >
      <div class="interior-offer-create-wrapper">
        <h2>인테리어 <span>비교 견적</span></h2>
        <hr />

        <div class="step-1" v-if="step === 1">
          <p class="description">어디에 어떤 공간인가요?</p>
          <AddressInput :onChange="onAddressChange" />

          <div class="category">
            <p class="label">분류 <span>*</span></p>
            <Radio
              v-for="item in classifications"
              v-model="categoryId"
              :value="item.id"
              :label="item.title"
              :key="item.id"
            />
          </div>

          <hr />
        </div>

        <div class="step-2" v-else-if="step === 2">
          <p class="description">공간 크기를 입력해주세요.</p>
          <div class="label">공급면적기준<span>*</span></div>
          <Input
            v-model="size"
            :type="'number'"
            :max="1000"
            :min="1"
            :placeholder="'면적단위(평)'"
          />
          <p class="hint">
            ※ 최소 3㎡(1평) 부터 최대 330㎡(1000평) 까지만 입력이 가능합니다.
          </p>
        </div>

        <div class="step-3" v-if="step === 3">
          <p class="description">공사가능 시기를 알려주세요</p>
          <div class="time">
            <p class="label">공사 가능한 날짜 <span>*</span></p>
            <Radio
              v-for="item in times"
              v-model="timeId"
              :value="item.id"
              :label="item.title"
              :key="item.id"
            />
          </div>
        </div>

        <div class="step-4" v-if="step === 4">
          <p class="description">어떤 공간을 만드시나요?</p>
          <div class="form">
            <p class="label">분류 <span>*</span></p>
            <Radio
              v-for="item in forms"
              v-model="formId"
              :value="item.id"
              :label="item.title"
              :key="item.id"
            />
          </div>
        </div>

        <div class="step-5" v-if="step === 5">
          <p class="description">인테리어 스타일은?</p>
          <div class="style">
            <p class="label">분류 <span>*</span></p>
            <Radio
              v-for="item in styles"
              v-model="styleId"
              :value="item.id"
              :label="item.title"
              :key="item.id"
            />
          </div>
        </div>

        <div class="step-6" v-if="step === 6">
          <p class="description">
            참고할 사진이 있으신가요?<br />
            <span>사진이 없으시면 다음으로 넘어가세요</span>
          </p>
          <FileInput
            :label="'참고사진'"
            :multiple="true"
            :onChange="onFilesChange"
          />
        </div>

        <div class="step-7" v-else-if="step === 7">
          <p class="description">예산은 어떻게 되시나요?</p>
          <div class="label">금액<span>*</span></div>
          <Input v-model="budget" :type="'number'" :placeholder="'원'" />
          <p class="hint">
            ※ 공사예산이 1,500만원 이상인 경우 실내건축공사업 면허가 있는
            사업자와 공사를 진행하여야 법적인 보호를 받을 수 있습니다.
          </p>
        </div>

        <div class="step-8 flex flex-col items-center" v-if="step === 8">
          <img src="@/assets/complete.png" />
          <p>
            견적요청이 완료되었습니다.<br />
            관리자 승인 후 인테리어 비교견적이 시작됩니다.
          </p>
        </div>

        <div class="button-group w-full flex flex-col">
          <button
            @click="onNext"
            v-if="step !== 8"
            class="next w-full flex justify-center items-center"
            :disabled="isLoading"
          >
            <span v-if="step !== 7">다음</span>
            <span v-else-if="!isLoading && step === 7">제출</span>
            <PulseLoader :loading="isLoading" :size="'8px'" :color="'white'" />
          </button>
          <button
            @click="onHome"
            v-if="step == 8"
            class="next flex justify-center items-center"
          >
            홈으로
          </button>
          <button
            @click="onBack"
            v-if="step !== 1 && step !== 8"
            class="back w-full flex justify-center items-center"
          >
            뒤로
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import AddressInput from "@/components/AddressInput.vue";
import Input from "@/components/Input.vue";
import Radio from "@/components/Radio.vue";
import FileInput from "@/components/FileInput.vue";
import Jumbotron2 from "@/components/Jumbotron2.vue";
import { mapState } from "vuex";
import services from "@/services";

export default {
  name: "InteriorOfferCreate",
  components: {
    PulseLoader,
    AddressInput,
    Input,
    Radio,
    FileInput,
    Jumbotron2,
  },
  data() {
    return {
      step: 0,
      isLoading: false,
      address: "",
      addressDetail: "",
      latitude: null,
      longitude: null,
      categoryId: null,
      size: null,
      timeId: null,
      formId: null,
      styleId: null,
      budget: null,
      innerFiles: [],
      otherFiles: [],
    };
  },
  methods: {
    onStart() {
      this.step = 1;
    },
    async onNext() {
      if (this.step === 1) {
        if (!this.address) {
          this.showError("주소를 입력해주세요");
          return;
        } else if (!this.addressDetail) {
          this.showError("상세주소를 입력해주세요");
          return;
        } else if (!this.categoryId) {
          this.showError("분류를 선택해주세요");
          return;
        }

        this.step = 2;
      } else if (this.step === 2) {
        if (!this.size) {
          this.showError("공급면적을 입력해주세요");
          return;
        }

        this.step = 3;
      } else if (this.step === 3) {
        if (!this.timeId) {
          this.showError("공사 가능한 날짜를 선택해주세요");
          return;
        }

        this.step = 4;
      } else if (this.step === 4) {
        if (!this.formId) {
          this.showError("분류를 선택해주세요");
          return;
        }

        this.step = 5;
      } else if (this.step === 5) {
        if (!this.styleId) {
          this.showError("분류를 선택해주세요");
          return;
        }

        this.step = 6;
      } else if (this.step === 6) {
        this.step = 7;
      } else if (this.step === 7) {
        if (!this.budget) {
          this.showError("예산을 입력해주세요");
          return;
        }

        this.loading = true;
        try {
          const success = await services.interiorOfferCreate({
            address: this.address,
            addressDetail: this.addressDetail,
            longitude: this.longitude,
            latitude: this.latitude,
            size: this.size,
            classificationId: this.categoryId,
            timeId: this.timeId,
            formId: this.formId,
            styleId: this.styleId,
            budget: this.budget,
            "innerFiles[]": this.innerFiles,
            "otherFiles[]": this.otherFiles,
          });

          if (success) {
            this.step = 8;
          } else {
            this.showError("문제가 발생했습니다");
          }
        } catch (e) {
          this.showError("문제가 발생했습니다");
        } finally {
          this.isLoading = false;
        }
      }
    },
    onHome() {
      this.$router.push({ name: "Home" });
    },
    onBack() {
      this.step = this.step - 1;
    },
    onAddressChange({ address, addressDetail, latitude, longitude }) {
      this.address = address;
      this.addressDetail = addressDetail;
      this.latitude = latitude;
      this.longitude = longitude;
    },
    onChangeCategory(value) {
      this.categoryId = value;
    },
    onFilesChange(value) {
      if (value.length > 0) {
        this.innerFiles = [value[0]];
        this.otherFiles = value.slice(1);
      } else {
        this.innerFiles = [value];
        this.otherFiles = [];
      }
    },
    showError(message) {
      this.$toast.open({ message, type: "error" });
    },
  },
  computed: {
    ...mapState("place", ["classifications", "forms"]),
    ...mapState("interior", ["times", "styles"]),
  },
};
</script>

<style scoped lang="scss">
.interior-offer-create-0 {
  margin-top: 120px;
  margin-bottom: 120px;

  h1 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -1.5px;
    text-align: center;
    color: #2e3ff0;
    margin-bottom: 30px;

    span {
      font-weight: normal;
    }
  }

  p {
    margin-bottom: 45px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    text-align: center;
    color: #434343;
  }

  button {
    width: 215px;
    height: 60px;
    border-radius: 3px;
    background-color: #2e3ff0;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: -0.16px;
    text-align: center;
    color: #fff;
  }
}

.interior-offer-create-wrapper {
  width: 588px;
  border: 1px solid #d7d7d7;
  padding: 74px 65px 49px;
  margin-top: 78px;
  margin-bottom: 171px;

  h2 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -1.2px;
    text-align: left;
    color: #333;
    margin-bottom: 35px;

    span {
      font-weight: 300;
    }
  }

  .description {
    margin-top: 38px;
    margin-bottom: 50px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #333;

    span {
      color: #283aef;
    }
  }

  .step-1,
  .step-2,
  .step-3,
  .step-4,
  .step-5,
  .step-6,
  .step-7 {
    margin-bottom: 80px;
  }

  .step-8 {
    margin-top: 70px;

    p {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.32;
      letter-spacing: -0.56px;
      text-align: center;
      color: #333;
      margin-top: 32px;
      margin-bottom: 58px;
    }
  }

  .category,
  .time,
  .form,
  .style {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 10px;

    p {
      margin-left: -10px;
      margin-bottom: 10px;
    }

    .checkbox {
      margin-bottom: 8px;
    }
  }

  .hint {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: -0.48px;
    text-align: left;
    color: #555;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.56px;
    text-align: left;
    color: #555;

    span {
      color: #f20000;
    }

    &:not(:first-child) {
      margin-top: 26px;
    }
  }

  .button-group {
    button {
      height: 55px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.68;
      letter-spacing: normal;
      text-align: left;
      color: #fff;

      &.next {
        background-color: #283aef;
      }
      &.back {
        margin-top: 7px;
        background-color: #585858;
      }
    }
  }
}

@media (max-width: 639px) {
  .interior-offer-create-0 {
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 13px;
    }
    button {
      font-size: 13px;
      height: 40px;
    }
  }
  .interior-offer-create-wrapper {
    width: 100%;
    border: none;
    padding: 30px 20px;
  }
}
</style>
